import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />

    <section className="page-404">
      <div className="page-404__wrapper">
        <h2>Oops! Keep digging..</h2>
        <Link to="/" className="btn_custom">
          Back to Home page
        </Link>
      </div>
    </section>
  </Layout>
)

export default NotFoundPage
